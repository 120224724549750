.app-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 1024px; /* as mentioned, your maximum width */
  margin: 0 auto;   /* centers the app-container horizontally */
  gap: 32px;        /* gap between columns */
  padding-top: 32px;
}

.left-column {
  width: 320px;
  position: sticky;
  top: 0;
  border-right: 2px solid #C1DFF0;
}

.content-area {
  width: 672px;
  overflow-y: auto; /* in case the content exceeds the view height */
}

.blue-line {
  border: 0;
  height: 3px;
  background-color: #3D75C4;
}

.blue-button {
  display: block;
  width: max-content; /* so that the width of the button remains content-based */
  margin: 0 auto;
  background-color: #3D75C4;
  color: #FFFFFF;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.blue-button.centered-button {
  display: block;
  width: max-content;
  margin: 0 auto;
  margin-bottom: 32px;
}

.content-area img {
  max-width: 100%;
  height: auto;
}

.contact-input-field {
  display: block;
  margin-bottom: 10px; /* adjust spacing as needed */
}

.hero-image {
  margin-bottom: 30px; /* adjust value as needed */
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* This creates two equally spaced columns */
  grid-gap: 20px; /* Spacing between grid items */
  width: 100%; 
  max-width: 800px; /* You can adjust this as per your design needs */
  margin: 0 auto; /* Centers the grid container */
  margin-bottom: 32px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  height: 200px; /* Adjust based on your needs */
  padding: 20px; /* Padding inside each block */
  border: none;
  border-radius: 4px;
  background-color: var(--secondary-color);
}

.grid-item h2 {
  margin-bottom: 10px;
  color: var(--header-color);
}

.grid-item p {
  text-align: center;
}

.work-experience {
  margin-top: 64px;
}

.work-item {
  padding: 5px 20px 20px 20px; /* Reduced top padding */
  border: 1px solid var(--secondary-color);
  margin-bottom: 15px;
  border-radius: 4px;
}

.work-item h3 {
  margin-bottom: -10px; /* Reduced spacing between title and company name */
  color: var(--header-color);
}

.work-item h4 {
  margin-bottom: -10px;
}

.work-item p:first-of-type {
  margin-bottom: -10px;
  font-style: italic;
  color: var(--primary-color);
}

.tag {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  padding: 5px 15px;
  border-radius: 4px;
  display: inline-block;
  margin: 5px 5px 5px 0;
}

.tag-filters {
  margin-bottom: 64px;
}

/* Add more spacing between checkbox sets (checkbox + label) */
.tag-filters label {
  margin-right: 15px;
}

.filter-toggle {
  margin-bottom: 16px;
}

.filter-container {
  background-color: var(--secondary-color);  /* the blue background */
  padding: 10px; /* give it some space inside */
  border-radius: 4px; /* rounded corners */
  margin-bottom: 32px; /* space below the container */
}

.filter-container label {
  white-space: nowrap;  /* Prevents the content inside the label from wrapping */
  display: block;       /* Makes the label behave like a block, thus placing each checkbox on a new line */
  margin-bottom: 10px;  /* Some space between the checkboxes */
}

.about-container {
  padding-bottom: 64px; /* Space from the top of the page */
}

.bold-text {
  font-weight: bold;
  margin-bottom: 16px; /* Space between the bold text and the next paragraph */
}

.about-content-wrapper {
  display: flex;
  align-items: flex-start; /* Aligns the top edges of the text and the image */
}

.text-content {
  flex: 1;
  margin-right: 16px; /* Space between the text and the image */
}

.about-image {
  width: 320px; /* Target size */
  max-width: 100%; /* Responsiveness */
  height: auto; 
}

.contact-container {
  text-align: left;
}

.contact-header {
  font-size: 64px;
  margin-top: 32px;
  margin-bottom: 32px;
  color: var(--header-color);
}

.contact-form-block {
  background-color: var(--tertiary-color); /* You can change this to your desired color */
  padding: 32px;
  border-radius: 4px;
  margin-top: 32px;
}

.contact-form-block input, 
.contact-form-block textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--primary-color); /* Light blue color, change as needed */
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 16px; /* Spacing between each input/textarea */
  font-family: 'DM Sans', sans-serif;  /* Setting the font to DM Sans */
  color: var(--header-color); /* Text color */
}

.contact-form-block textarea {
  height: 150px; /* Adjust this value based on how tall you want the textarea to be */
}

/* If you want a focus effect for your inputs and textareas */
.contact-form-block input:focus, 
.contact-form-block textarea:focus {
  outline: none;
  box-shadow: 0 0 5px var(--header-color);
}
