/* Add styles specific to the LeftColumn component. 
You can utilize the global styles and classes we previously created. 
This is just a starting point; adjust it as needed. */

.left-column {
    width: 288px;
    padding-right: 29px;
    position: sticky;
    top: 0;
}

.logo {
    width: 100%;
}

.subheader,
.footer-info {
    color: var(--primary-color);
}

.divider {
    width: 100%;
    height: 3px;
    background-color: var(--secondary-color);
    margin: 32px 0;
    border: none;
}

.navigation {
    display: block;
    margin-bottom: 10px; /* adjust spacing as needed */
}

.navigation a {
    text-decoration: none; /* removes underline */
    color: var(--primary-color);
}

.navigation a:visited {
    color: var(--primary-color);
}

.navigation a.active {
    font-weight: bold;
}

.newsletter-box {
    background-color: var(--tertiary-color);
    padding: 6px;
}

.newsletter-box input {
    margin-right: 8px; /* adjust as needed */
    border: 1px solid var(--primary-color);
    background-color: var(--white-color);
    border-radius: 3px;
    font-family: 'DM Sans', sans-serif;  /* Setting the font to DM Sans */
    color: var(--header-color);         /* Setting the font color */
}

.newsletter-box .btn {
    margin-top: 16px;
}
